import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_Button = _resolveComponent("Button")
  const _component_Toolbar = _resolveComponent("Toolbar")
  const _component_Column = _resolveComponent("Column")
  const _component_TreeTable = _resolveComponent("TreeTable")
  const _component_Dropdown = _resolveComponent("Dropdown")
  const _component_FileUpload = _resolveComponent("FileUpload")
  const _component_Dialog = _resolveComponent("Dialog")
  const _component_InputText = _resolveComponent("InputText")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Toolbar, null, {
      start: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Upload Files",
          icon: "pi pi-upload",
          class: "p-button-success p-mr-2",
          onClick: _ctx.openUploadDialog
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          label: "Create Folder",
          icon: "pi pi-folder",
          class: "p-button-success p-mr-2",
          onClick: _ctx.openMkDirDialog
        }, null, 8, ["onClick"])
      ]),
      _: 1
    }),
    _createVNode(_component_TreeTable, {
      value: _ctx.nodes,
      lazy: true,
      loading: _ctx.loading,
      onNodeExpand: _ctx.onExpand,
      autoLayout: true,
      expandedKeys: _ctx.expandedKeys,
      resizableColumns: true,
      columnResizeMode: "fit"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "name",
          header: "Name",
          expander: true
        }),
        _createVNode(_component_Column, {
          field: "size",
          header: "Size"
        }, {
          body: _withCtx(({node}) => [
            _createTextVNode(_toDisplayString(_ctx.prettyBytes(node.data.size, node.data.type)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          field: "type",
          header: "Type"
        }),
        _createVNode(_component_Column, { header: "Actions" }, {
          body: _withCtx(({node}) => [
            (node.leaf || (node.children && node.children.length === 0))
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  icon: "pi pi-trash",
                  disabled: _ctx.loading,
                  class: "p-button-rounded p-button-danger p-mr-2",
                  onClick: $event => (_ctx.onDelete(node))
                }, null, 8, ["disabled", "onClick"]))
              : _createCommentVNode("", true),
            (node.data.type === 'File')
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  icon: "pi pi-download",
                  class: "p-button-rounded p-button-success p-mr-2",
                  onClick: $event => (_ctx.onDownload(node))
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "loading", "onNodeExpand", "expandedKeys"]),
    _createVNode(_component_Dialog, {
      header: "Upload Files",
      visible: _ctx.showUploadDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.showUploadDialog) = $event)),
      style: {width: '50vw'}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.selectedFolder,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectedFolder) = $event)),
          options: _ctx.foldersList,
          class: "p-mb-5",
          style: {width: '100%'},
          placeholder: "Select Folder"
        }, null, 8, ["modelValue", "options"]),
        _createVNode(_component_FileUpload, {
          disabled: !_ctx.selectedFolder,
          name: "demo[]",
          customUpload: true,
          multiple: true,
          showCancelButton: false,
          onUploader: _ctx.uploader
        }, null, 8, ["disabled", "onUploader"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      header: "Create Folder",
      visible: _ctx.showMkDirDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = $event => ((_ctx.showMkDirDialog) = $event)),
      style: {width: '50vw'}
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cancel",
          icon: "pi pi-times",
          onClick: _ctx.closeMkDirDialog,
          class: "p-button-text"
        }, null, 8, ["onClick"]),
        _createVNode(_component_Button, {
          disabled: !_ctx.folderToCreate || !_ctx.selectedFolder,
          label: "Create",
          icon: "pi pi-check",
          onClick: _ctx.makeDirectory,
          autofocus: ""
        }, null, 8, ["disabled", "onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.selectedFolder,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.selectedFolder) = $event)),
          options: _ctx.foldersList,
          class: "p-mb-5",
          style: {width: '100%'},
          placeholder: "Parent Folder"
        }, null, 8, ["modelValue", "options"]),
        _createVNode(_component_InputText, {
          id: "folderName",
          type: "text",
          modelValue: _ctx.folderToCreate,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.folderToCreate) = $event)),
          placeholder: "Folder Name",
          style: {width: '100%'}
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}